import { Alert, Avatar, Backdrop, Box, Button, Card, CardContent, CircularProgress, Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Tjenesteknapper from '../komponenter/Tjenesteknapper';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
const APIURL = process.env.REACT_APP_APIURL;

function Forsiden() {
    const [isLoading, setisLoading] = useState(true);
    const jvbruker = sessionStorage.getItem('jvbruker');
    const jvnavn = sessionStorage.getItem('jvnavn');
    const jvmobilnummer = sessionStorage.getItem('jvmobilnummer');
    const jvaktornavn = sessionStorage.getItem('jvaktornavn');
    const jvaktororgnr = sessionStorage.getItem('jvaktororgnummer');
    const token = sessionStorage.getItem("jvtoken") || "";
    const jvsesaktorid = sessionStorage.getItem('jvaktorid') || "";

    const [jvbrukerverifisert, setjvbrukerverifisert] = useState(false);
    const [jvmobilnummerverifisert, setjvmobilnummerverifisert] = useState(false);
    const [jvaktorverifisert, setjvaktorverifisert] = useState(false);



    const [dsKontaktpersonNavn, setdsKontaktpersonNavn] = useState('');
    const [dsKontaktpersonEpost, setdsKontaktpersonEpost] = useState('');
    const [dsKontaktpersonMobilnummer, setdsKontaktpersonMobilnummer] = useState('');
    const [dsKontaktpersonEpostVerifisert, setdsKontaktpersonEpostVerifisert] = useState(false);
    const [dsKontaktpersonMobilnummerVerifisert, setdsKontaktpersonMobilnummerVerifisert] = useState(false);
    const [dsKontaktpersonSisteinnlogging, setdsKontaktpersonSisteinnlogging] = useState('');
    const [dsKontaktpersonSpraak, setdsKontaktpersonSpraak] = useState('');
    const [dsKontaktpersonTelefonnummer, setdsKontaktpersonTelefonnummer] = useState('');
    const [dsKontaktpersonAktorRolle, setdsKontaktpersonAktorRolle] = useState('');

    function hentProfil() {
        axios.get(APIURL + "/profil", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    // let AktorId = response.data.AktorId;
                    let KontaktpersonId = response.data.KontaktpersonId;
                    let Navn = response.data.Navn;
                    setdsKontaktpersonNavn(Navn);
                    let Epost = response.data.Epost;
                    setdsKontaktpersonEpost(Epost);
                    let EpostVerifisert = response.data.EpostVerifisert;
                    setdsKontaktpersonEpostVerifisert(EpostVerifisert);
                    let Mobilnummer = response.data.Mobilnummer;
                    setdsKontaktpersonMobilnummer(Mobilnummer);
                    let MobilnummerVerifisert = response.data.MobilnummerVerifisert;
                    setdsKontaktpersonMobilnummerVerifisert(MobilnummerVerifisert);
                    let Sisteinnlogging = response.data.Sisteinnlogging;
                    setdsKontaktpersonSisteinnlogging(Sisteinnlogging);
                    let Spraak = response.data.Spraak;
                    setdsKontaktpersonSpraak(Spraak);
                    let Telefonnummer = response.data.Telefonnummer;
                    setdsKontaktpersonTelefonnummer(Telefonnummer);
                    let AktorRolle = response.data.AktorRolle;
                    setdsKontaktpersonAktorRolle(AktorRolle);

                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setisLoading(false);
            });
    }


    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        if (token !== "") {
            setisLoading(false);
        }
        else {
            window.location.assign("/logginn");
        }
        if ((jvsesaktorid === "undefined") || (jvsesaktorid === null)) {
            window.location.assign("/profil");
        }
        const jvbrukerverifisert = sessionStorage.getItem('jvbrukerverifisert');
        const isVerified = jvbrukerverifisert === 'true';
        setjvbrukerverifisert(isVerified);
        const jvmobilnummerverifisert = sessionStorage.getItem('jvmobilnummerverifisert');
        const isVerifiedMobil = jvmobilnummerverifisert === 'true';
        setjvmobilnummerverifisert(isVerifiedMobil);
        const jvaktorverifisert = sessionStorage.getItem('jvaktorverifisert');
        const isVerifiedAktor = jvaktorverifisert === 'true';
        setjvaktorverifisert(isVerifiedAktor);
        hentProfil();
    }, [token, jvsesaktorid]);

    return (
        <main>
            {
                (isLoading) ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    (token && (jvsesaktorid !== "undefined") && (jvsesaktorid !== null)) &&
                    <div>
                        <ToppMeny />
                        <Grid container spacing={2} p={2}>
                            <Grid size={{ xs: 12, md: 6, lg: 8 }}>
                                {(!jvbrukerverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="epostikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="gjennomforeverifisering" /></Alert>
                                }
                                {(!jvmobilnummerverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="mobilikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="gjennomforeverifisering" /></Alert>
                                }
                                <Typography variant="h5" color="text.secondary" component="h2" sx={{ mt: 2, mb: 3 }}>
                                    <Text tid="tjenester" />
                                </Typography>
                                {(!jvaktorverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="dintilknytningaktor" /> <b>{jvaktornavn}</b> <Text tid="erikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="formerinformasjon" /></Alert>
                                }
                                <Grid container spacing={2} sx={{ mb: 5 }}>
                                    <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ minWidth: 300 }}>
                                        <Tjenesteknapper link={jvaktorverifisert ? "/taksameter" : "#"} Icon="LocalTaxiIcon" Tittel="taksameter" Info="servicetaksameterinfo" Aktiv={jvaktorverifisert} />
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ minWidth: 300 }}>
                                        <Tjenesteknapper link={jvaktorverifisert ? "/innsending" : "#"} Icon="AssignmentIcon" Tittel="innsending" Info="innsendinginfo" Aktiv={jvaktorverifisert} />
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ minWidth: 300 }}>
                                        <Tjenesteknapper link={jvaktorverifisert ? "/aarsavgift" : "#"} Icon="PointOfSaleIcon" Tittel="aarsavgift" Info="aarsavgiftinfo" Aktiv={jvaktorverifisert} />
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ minWidth: 300 }}>
                                        <Tjenesteknapper link={jvaktorverifisert ? "/arkiv" : "#"} Icon="FolderIcon" Tittel="arkiv" Info="arkivinfo" Aktiv={jvaktorverifisert} />
                                    </Grid>
                                </Grid>
                                <Alert severity="info">
                                    Dersom du er en servicebedrift (ikke for taksametere) og skal ha tilgang til en aktør ved bruk av en servicekode som du har fått oppgitt av aktøren du sender melding på vegne av, oppgi denne etter at du har søkt opp aktøren under Innsending.
                                </Alert>
                                <Alert severity="info" sx={{ mt: 2 }}>
                                    Dersom du har spørsmål til hvordan du skal bruke Din side, kan du finne mange svar på vår <Link href="/ofte-stilte-sporsmaal">ofte stilte spørsmål</Link> side.
                                </Alert>
                            </Grid>

                            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                                <Card
                                    sx={{
                                        borderRadius: 3,
                                        boxShadow: 3,
                                        textAlign: "center",
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" fontWeight="bold" display="flex" justifyContent="center">
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <AccountCircle fontSize="small" />
                                                {dsKontaktpersonNavn}
                                            </Box>
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold" color="text.secondary" display="flex" justifyContent="center" sx={{ mb: 1 }}>
                                            {dsKontaktpersonEpost} {dsKontaktpersonEpostVerifisert ? <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} /> : <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} />}
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold" color="text.secondary" display="flex" justifyContent="center">
                                            {dsKontaktpersonMobilnummer} {dsKontaktpersonMobilnummerVerifisert ? <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} /> : <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} />}
                                        </Typography>
                                        <hr />
                                        <Typography variant="h6" fontWeight="bold" display="flex" justifyContent="center">
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <BusinessIcon fontSize="small" />
                                                {jvaktornavn} {(!jvaktorverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                            </Box>
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold" color="text.secondary" display="flex" justifyContent="center">
                                            {jvaktororgnr}
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold" color="text.secondary" display="flex" justifyContent="center">
                                            {dsKontaktpersonAktorRolle}
                                        </Typography>
                                        <Box display="flex" justifyContent="end" gap={3} mt={5}>
                                            <Button
                                                size="small"
                                                href="/profil"
                                                variant="outlined"
                                                startIcon={<SettingsIcon />}
                                            >
                                                <Text tid="minprofil" />
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>

                            </Grid>

                        </Grid>
                    </div>
            }
        </main>
    );
}

export default Forsiden;
import { Alert, Backdrop, Breadcrumbs, Button, CircularProgress, FormControl, InputLabel, Link, Typography, TextField, Select, MenuItem, InputAdornment, AlertTitle, Divider, Card, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid2';
import { QuestionMark } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const APIURL = process.env.REACT_APP_APIURL;

function OfteStilteSporsmaal() {
    const token = sessionStorage.getItem("jvtoken") || "";

    return (
        <main>
            {token &&
                (
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                Ofte stilte spørsmål
                            </Typography>
                        </Breadcrumbs>

                        <Grid container spacing={2} p={2}>
                            <Grid size={12}>
                                <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <HelpOutlineIcon sx={{ mr: 1 }} /> Ofte stilte spørsmål
                                </Typography>
                            </Grid>

                            <Grid size={12}>
                                <Card sx={{ mb: 2, p: 2 }}>
                                    <Typography variant="h6" sx={{ mb: 1 }}>Generelle spørsmål</Typography>
                                    <Accordion key={"g1"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan knytter jeg min brukerprofil til min virksomhet?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Oppgi organisasjonens hovedorganisasjonsnummer.
                                                <br />
                                                - Be om brev om Passordkode til Altinn, som du må legge inn på Dinside for å få administratorrettigheter og tilgang.
                                                <br />
                                                - Alternativt kan en eksisterende administrator verifisere deg.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g2"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvem mottar brev med passord i Altinn?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Passordet sendes til den som er Meldt som eier i Altinn.
                                                <br />
                                                - Kun den juridiske eieren eller en administrator med riktige rettigheter i Altinn kan motta og dele passordet.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g3"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan knytter jeg et servicefirma til min virksomhet?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                Du kan knytte et servicefirma til virksomheten din på to måter:
                                                <br />
                                                &nbsp;
                                                <br />
                                                <b>1. Bruke servicekode</b>
                                                <br />
                                                - Logg inn på Dinside
                                                <br />
                                                - Gå til "Min profil" → "Administrer tilganger"
                                                <br />
                                                - Finn "Servicekode", kopier den og del den med servicefirmaet
                                                <br />
                                                &nbsp;
                                                <br />
                                                <b>2. Søke opp servicefirmaet</b>
                                                <br />
                                                - Logg inn på Dinside
                                                <br />
                                                - Gå til "Arkiv"
                                                <br />
                                                - Gå til "Min profil" → "Administrer tilganger" → "Tilknyttede servicebedrifter"
                                                <br />
                                                - Klikk på (+) "Legg til servicefirma"
                                                <br />
                                                - Søk opp firmaet ved å bruke hovedorganisasjonsnummer eller firmaets navn
                                                <br />
                                                - Klikk på "Tilknytt"
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g4"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvor finner jeg servicekode til min virksomhet?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Logg inn på Dinside
                                                <br />
                                                - Gå til "Min profil" → "Administrer tilganger"
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g5"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvor lenge varer tilgangen for et servicefirma?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Tilgangen er permanent til du selv fjerner den via "Administrer tilganger" i Dinside.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g6"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan fjerner jeg tilgangen til et servicefirma?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Logg inn på Dinside
                                                <br />
                                                - Gå til "Min profil" → "Administrer tilganger"
                                                <br />
                                                - Finn firmaet under "Tilknyttede servicebedrifter" og fjern tilgangen
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g7"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Problemer med registrering av engangspassord</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                Hvis du har problemer med å registrere engangspassordet for å bli administrator, prøv følgende:<p></p>
                                                <b>Bruk engangspassordet riktig:</b><p></p>
                                                •	Når du mottar engangspassordet i Altinn, skriv det inn eller kopier fra brevet og  lim det inn i feltet.<br />
                                                •	Viktig: Ikke trykk "Enter" etter å ha skrevet inn passordet.<br />
                                                •	Klikk på "SEND INN"-knappen for å bekrefte.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"g8"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Kan Justervesenet opprette deg som administrator?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                Nei, Justervesenet kan ikke opprette deg som administrator. Du må selv følge disse trinnene:<p></p>
                                                <b>Tilknytt din profil til virksomheten</b><p></p>
                                                •	Etter pålogging, gå til "Min profil" og velg "Tilknytt virksomhet".<br />
                                                •	Oppgi virksomhetens hovedorganisasjonsnummer.<br />
                                                •	Be om et engangspassord via Altinn, som sendes til virksomhetens eier.<br />
                                                •	Legg inn engangspassordet for å bekrefte tilknytningen.<p></p>
                                                <b>Bli administrator</b><p></p>
                                                •	Når profilen din er tilknyttet virksomheten, kan du gi andre i virksomheten administratorrettigheter.<br />
                                                •	Administratorer har full tilgang til systemet og kan tildele roller til andre brukere.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Innsendinger</Typography>
                                    <Accordion key={"i1"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan sjekker jeg innsendte meldinger til Justervesenet?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - All data vises i sanntid på instrumentet.
                                                <br />
                                                - Hver melding får et unikt referansenummer, som vises på instrumentet.
                                                <br />
                                                - Bekreftelse på utført retting etter avvik sendes via Altinn til instrumentets eier.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"i2"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hva gjør jeg hvis jeg har meldt inn feil informasjon om et instrument med aktivt pålegg?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Send en e-post til postmottak@justervesenet.no med saksnummer og beskrivelse av feilen.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"i3"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Kan jeg bruke nettleserens søkefunksjon for informasjon fra instrumentvisningen?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Ja, dersom du bruker windows kan du bruke tastekombinasjonen CTRL+F for å søke etter tekst på siden. Bruker du Mac kan du bruke kombinasjonen CMD+F.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"i4"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hva betyr fargekodene i instrumentoversikten?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                🔴 Rødt = Stanset (må rettes før bruk)
                                                <br />
                                                🟠 Oransje = Pålegg om retting (må rettes innen frist)
                                                <br />
                                                ✅ Grønt = Ikke inspisert siden forrige kontroll.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"i5"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan melder jeg frivillig kalibrering av et måleinstrument?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Velg "Innsending"
                                                <br />
                                                - Søk etter virksomheten
                                                <br />
                                                - Finn måleinstrumentet i oversikten
                                                <br />
                                                - Klikk på "Send melding" → Velg "Kalibrering" som årsak
                                                <br />
                                                - Fyll ut de påkrevde feltene
                                                <br />
                                                - Klikk "Send"
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"i6"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan melder jeg et solgt måleinstrument?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Velg "Innsending"
                                                <br />
                                                - Søk etter virksomheten
                                                <br />
                                                - Gå til instrumentoversikten
                                                <br />
                                                - Velg det solgte instrumentet
                                                <br />
                                                - Klikk på "Kasser instrument" og huk av for "Solgt måleredskap"
                                                <br />
                                                - Oppgi navn, adresse og organisasjonsnummer til kjøperen i årsaksfeltet
                                                <br />
                                                - Klikk "Kasser instrument"
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Taksameter</Typography>
                                    <Accordion key={"t1"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Jeg finner ikke firmanavn, organisasjonsnummer eller løyvenummer</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Sjekk at informasjonen er korrekt.
                                                <br />
                                                - Hvis firmaet ikke er Meldt, må du opprette en ny aktør med gyldig organisasjonsnummer og løyvenummer.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t2"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan melder jeg inn plombebrudd og reparasjon?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Søk opp løyvehaver med organisasjonsnummer eller saksreferanse
                                                <br />
                                                - Send melding og oppgi årsak og detaljer i kommentarfeltet
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t3"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hva gjør jeg hvis informasjon mangler på et taksameter i drift?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Oppdater taksameteret med korrekt informasjon. Oppgi årsak i kommentarfeltet.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t4"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan fjerner jeg et ukjent taksameter knyttet til en løyvehaver?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Velg "Utmontering" og legg til årsak/kommentar.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t5"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan håndterer jeg endring av løyve ved ny eier/organisasjonsnummer?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Meld utmontering av taksameteret fra den tidligere eieren.
                                                <br />
                                                - Meld deretter installasjonskontroll for den nye eieren.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t6"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan melder jeg en avsluttet drosjevirksomhet?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Velg "Utmontering", legg til årsak, kommentar og opphørsdato.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t7"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan melder jeg et løyve med samme bil og taksameter, men ny løyvehaver?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Meld utmontering på den tidligere eieren.
                                                <br />
                                                - Meld nytt kjøretøy på den nye eieren.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t8"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan melder jeg ny installasjonskontroll av taksameter?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                - Fane drosjeløyver uten taksameter gir oversikt over gyldige drosjeløyver tilgjengelig for melding om installasjonskontroll. Du vil bli bedt om å velge en årsak fra en nedtrekksmeny. Deretter må du fylle ut all nødvendig informasjon om kjøretøyet og taksameteret, inkludert informasjon om plombering. Du kan legge til flere plomber ved å trykke på pluss-ikonet.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"t9"}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="body1">Hvordan endrer jeg ID på et taksameter?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2" color="text.secondary">
                                                Hvis taksameteret blir overtatt av en annen løyve eller bil, må du:
                                                <br />
                                                - Meld utmontering av taksameteret og oppgi årsaken i kommentarfeltet.
                                                <br />
                                                - Meld deretter et nytt taksameter med riktig informasjon.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Card>



                                <Alert severity="info" sx={{ mt: 5 }}>
                                    <Typography variant="body2">Dersom du trenger ytterligere hjelp, kontakt Justervesenets brukerstøtte: <b>64 84 84 84</b> eller <Link href="mailto:fls@justervesenet.no">fls@justervesenet.no</Link></Typography>
                                </Alert>

                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </main >
    );
}

export default OfteStilteSporsmaal;
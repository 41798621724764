import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputLabel, Link, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import LanguageIcon from '@mui/icons-material/Language';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PasswordIcon from '@mui/icons-material/Password';
import SyncIcon from '@mui/icons-material/Sync';
import Grid from '@mui/material/Grid2';


const APIURL = process.env.REACT_APP_APIURL;

function Admin() {
    const [isLoading, setisLoading] = useState(true);
    const { orgnr } = useParams<string>();
    const token = sessionStorage.getItem("jvtoken") || "";
    const [aktorData, setAktorData] = useState<any[]>([]);
    const [redigerAktorModal, setredigerAktorModal] = useState(false);
    const [slettBrukerModal, setslettBrukerModal] = useState(false);
    const [redigerBrukerModal, setredigerBrukerModal] = useState(false);
    const [aktivKontaktpersonId, setAktivKontaktpersonId] = useState(0);
    const [aktivKontaktpersonNavn, setAktivKontaktpersonNavn] = useState("");
    const [aktivKontaktpersonRolle, setAktivKontaktpersonRolle] = useState("");
    const [aktivKontaktpersonTilgang, setAktivKontaktpersonTilgang] = useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [servicebedriftTilknyttModal, setservicebedriftTilknyttModal] = useState(false);
    const [isLoadingAktorSok, setisLoadingAktorSok] = useState(false);
    const [aktorsok, setAktorsok] = useState<any[]>([]);
    const [slettServicebedriftModal, setslettServicebedriftModal] = useState(false);
    const [aktivServicebedriftNavn, setAktivServicebedriftNavn] = useState("");
    const [aktivServicebedriftId, setAktivServicebedriftId] = useState<string>("");
    const [RollerogTillatelser, setRollerogTillatelser] = useState(false);

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const handleClickRedigerBrukerModalOpen = (KontaktpersonId: number, KontaktpersonNavn: string, Admin: any, Verifisert: any, Rolle: string) => {
        setAktivKontaktpersonId(KontaktpersonId);
        setAktivKontaktpersonNavn(KontaktpersonNavn);
        setAktivKontaktpersonRolle(Rolle);
        setAktivKontaktpersonTilgang(0);
        if (Verifisert) { setAktivKontaktpersonTilgang(1) };
        if (Admin) { setAktivKontaktpersonTilgang(2); }
        setredigerBrukerModal(true);
    }

    const handleClickRedigerBrukerModalClose = () => {
        setredigerBrukerModal(false);
    }

    const handleClickSlettBrukerModalOpen = (KontaktpersonId: number, KontaktpersonNavn: string) => {
        setAktivKontaktpersonId(KontaktpersonId);
        setAktivKontaktpersonNavn(KontaktpersonNavn);
        setslettBrukerModal(true);
    }

    const handleClickSlettBrukerModalClose = () => {
        setslettBrukerModal(false);
    }

    const handleClickRedigerAktorModalOpen = () => {
        setredigerAktorModal(true);
    };

    const handleClickRedigerAktorModalClose = () => {
        setredigerAktorModal(false);
    };

    const handleOpenModalNyServicebedrift = () => {
        setservicebedriftTilknyttModal(true);
    };

    const handleCloseModalNyServicebedrift = () => {
        setservicebedriftTilknyttModal(false);
    };

    const handleClickSlettServicebedriftModalOpen = (orgnr: string, servicebedriftNavn: string) => {
        setAktivServicebedriftId(orgnr);
        setAktivServicebedriftNavn(servicebedriftNavn);
        setslettServicebedriftModal(true);
    }

    const handleClickSlettServicebedriftModalClose = () => {
        setslettServicebedriftModal(false);
    }


    function hentAktor(orgnr: any) {
        axios.get(APIURL + "/aktoradmin/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                console.log(response.data[0]);
                if (response.status === 200) {
                    setAktorData(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: Error) => {
                setisLoading(false);
            });
    }

    const handleSokAktor = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoadingAktorSok(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            aktorsok: data.get('aktorsok'),
        };
        axios.post(APIURL + "/profil/aktorsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorsok(response.data);
                }
                else {
                }
                setisLoadingAktorSok(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoadingAktorSok(false);
            });
    }

    const handleSubmitSlettBruker = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            Organisasjonsnummer: orgnr,
            KontaktpersonId: aktivKontaktpersonId,
            Aarsak: data.get('sletteaarsak')
        };
        axios.post(APIURL + "/aktoradmin/kontaktpersonslett", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    hentAktor(orgnr);
                    setSnackMelding("Bruker " + aktivKontaktpersonNavn + " slettet fra " + aktorData[0]?.Navn);
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Feil under sletting av " + aktivKontaktpersonNavn);
                    setOpenSnackError(true);
                }
                handleClickSlettBrukerModalClose();
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response?.status === 401) || (e.response?.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                handleClickSlettBrukerModalClose();
                setisLoading(false);
            });
    }

    const handleSubmitSyncBesoksadresse = () => {
        setisLoading(true);
        var formdata = {
            orgnr: orgnr,
        };
        axios.post(APIURL + "/aktoradmin/aktorsyncbesoksadresse", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setSnackMelding("Besøksadresse synkronisert fra Brønnøsyund");
                    setOpenSnack(true);
                    hentAktor(orgnr);
                }
                else {
                    setSnackMelding("Feil under synkronisering av besøksadresse fra Brønnøysund");
                    setOpenSnackError(true);
                }
                handleClickSlettBrukerModalClose();
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
            });
    }

    const handleSubmitSyncPostadresse = () => {
        setisLoading(true);
        var formdata = {
            orgnr: orgnr,
        };
        axios.post(APIURL + "/aktoradmin/aktorsyncpostadresse", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setSnackMelding("Postadresse synkronisert fra Brønnøsyund");
                    setOpenSnack(true);
                    hentAktor(orgnr);
                }
                else {
                    setSnackMelding("Feil under synkronisering av postadresse fra Brønnøysund");
                    setOpenSnackError(true);
                }
                handleClickSlettBrukerModalClose();
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
            });
    }

    const handleSubmitEndreBruker = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            orgnr: orgnr,
            KontaktpersonId: aktivKontaktpersonId,
            KontaktpersonTilgang: data.get('brukertilgang'),
            KontaktpersonRolle: data.get('rolle')
        };
        await axios.put(APIURL + "/aktoradminkontaktperson", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    hentAktor(orgnr);
                    setSnackMelding("Oppdaterte opplysninger/tilgang på " + aktivKontaktpersonNavn + " for aktør + " + aktorData[0]?.Navn);
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Feil under oppdatering av bruker " + aktivKontaktpersonNavn);
                    setOpenSnackError(true);
                }
                handleClickRedigerBrukerModalClose();
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
        hentAktor(orgnr);
    }

    const handleSubmitEndreAktor = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            orgnr: orgnr,
            Epost: data.get('Epost'),
            Mobilnummer: data.get('Mobilnummer'),
            Telefonnummer: data.get('Telefonnummer'),
            Webside: data.get('Webside')
        };
        await axios.post(APIURL + "/aktoradmin/oppdater", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    hentAktor(orgnr);
                    setSnackMelding("Oppdaterte opplysninger på aktør " + aktorData[0]?.Navn);
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Feil under oppdatering av aktør " + aktorData[0]?.Navn);
                    setOpenSnackError(true);
                }
                handleClickRedigerAktorModalClose();
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
        hentAktor(orgnr);
    }

    const handleSubmitTilknyttServicebedrift = (orgnrtilgang: number) => {
        setisLoading(true);
        handleCloseModalNyServicebedrift();
        var formdata = {
            orgnreier: orgnr,
            orgnrtilgang: orgnrtilgang
        };
        axios.post(APIURL + "/aktoradmin/aktortilgang", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    hentAktor(orgnr);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
            });
    }

    const handleSubmitSlettServicebedrift = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            orgnreier: orgnr,
            orgnrtilgang: aktivServicebedriftId,
            Aarsak: data.get('sletteaarsak'),
            action: 'delete'
        };
        axios.post(APIURL + "/aktoradmin/aktortilgangslett", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setSnackMelding("Servicebedrift " + aktivServicebedriftNavn + " ble slettet.");
                    setOpenSnack(true);
                    hentAktor(orgnr);
                } else {
                    setSnackMelding("Feil under sletting av servicebedrift " + aktivServicebedriftNavn);
                    setOpenSnackError(true);
                }
                handleClickSlettServicebedriftModalClose();
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    useEffect(() => {
        document.title = 'Admin - Justervesenet';
        hentAktor(orgnr);
        setisLoading(false);
        // eslint-disable-next-line
    }, []);

    return (
        <main>
            {isLoading ? (
                <Backdrop
                    open={isLoading}
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop >
            ) : (
                <div>
                    <ToppMeny />
                    <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href="/forsiden"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            <Text tid="forsiden" />
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            <Text tid="administrator" />
                        </Typography>
                    </Breadcrumbs>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                            {snackMelding}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                        <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                            {snackMelding}
                        </Alert>
                    </Snackbar>

                    <Grid container spacing={2} p={2}>
                        <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                    <BusinessIcon className='jvcardtitleicon' /> <Text tid="aktor" />
                                </Typography>
                            </Box>

                            {Array.isArray(aktorData) && aktorData.length > 0 && (
                                aktorData.map(({ AktorId, Navn, Alias, Epost, Mobilnummer, Telefonnummer, Webside, OrgNummer, Besoksadresse, BesoksPostnummer, BesoksPoststed, Postadresse, PostPostnummer, PostPoststed }: any, index: number) => {
                                    return (
                                        <Card variant="outlined" sx={{ mb: 2 }} key={index}>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid size={12}>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {Navn}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                            <ListAltIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {OrgNummer}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                            <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Telefonnummer}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                            <SmartphoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Mobilnummer}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                            <AlternateEmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Epost}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                            <LanguageIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Webside}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1, ml: 3 }}>
                                                            <Button variant="outlined" size="small" color="warning" onClick={handleClickRedigerAktorModalOpen}>
                                                                <EditIcon sx={{ mr: 1 }} /> <Text tid="rediger" />
                                                            </Button>
                                                        </Box>
                                                    </Grid>

                                                    <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                            <MailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Postadresse}, {PostPostnummer} {PostPoststed}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 4 }}>
                                                            <HomeIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {Besoksadresse}, {BesoksPostnummer} {BesoksPoststed}
                                                            </Typography>
                                                        </Box>


                                                        {aktorData[0]?.brreg && (
                                                            (aktorData[0]?.brreg.forretningsadr) &&
                                                            ((aktorData[0].brreg.forretningsadr?.trim() && aktorData[0].brreg.forretningsadr.trim() !== aktorData[0].Besoksadresse.trim()) ||
                                                                (aktorData[0].brreg.forradrpostnr?.trim() && aktorData[0].brreg.forradrpostnr.trim() !== aktorData[0].BesoksPostnummer.trim()) ||
                                                                (aktorData[0].brreg.forradrpoststed?.trim() && aktorData[0].brreg.forradrpoststed.trim() !== aktorData[0].BesoksPoststed.trim())) && (
                                                                <Card variant="outlined" sx={{ borderColor: 'red' }}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1, mt: 2 }}>
                                                                        <Typography variant="body1" color='text.secondary'>
                                                                            <Text tid="brregbesoksadresseavvik" />
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                                        <HomeIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                                        <Typography variant="body2" color="text.secondary">
                                                                            {aktorData[0].brreg?.forretningsadr}, {aktorData[0]?.brreg?.forradrpostnr} {aktorData[0].brreg?.forradrpoststed}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1, ml: 3, mb: 2 }}>
                                                                        <Button variant="outlined" size="small" color="success" onClick={handleSubmitSyncBesoksadresse}>
                                                                            <SyncIcon sx={{ mr: 1 }} /> <Text tid="synkroniser" />
                                                                        </Button>
                                                                    </Box>
                                                                </Card>
                                                            )
                                                        )}

                                                        {aktorData[0]?.brreg && (
                                                            (aktorData[0]?.brreg.postadresse) &&
                                                            ((aktorData[0].brreg.postadresse?.trim() && aktorData[0].brreg.postadresse.trim() !== aktorData[0].Postadresse.trim()) ||
                                                                (aktorData[0].brreg.ppostnr?.trim() && aktorData[0].brreg.ppostnr.trim() !== aktorData[0].PostPostnummer.trim()) ||
                                                                (aktorData[0].brreg.ppoststed?.trim() && aktorData[0].brreg.ppoststed.trim() !== aktorData[0].PostPoststed.trim())) && (
                                                                <Card variant="outlined" sx={{ borderColor: 'red' }}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1, mt: 2 }}>
                                                                        <Typography variant="body1" color='text.secondary'>
                                                                            <Text tid="brregpostadresseavvik" />
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                                        <MailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                                        <Typography variant="body2" color="text.secondary">
                                                                            {aktorData[0].brreg?.postadresse}, {aktorData[0]?.brreg?.ppostnr} {aktorData[0]?.ppoststed}
                                                                        </Typography>
                                                                    </Box>

                                                                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1, ml: 3, mb: 2 }}>
                                                                        <Button variant="outlined" size="small" color="success" onClick={handleSubmitSyncPostadresse}>
                                                                            <SyncIcon sx={{ mr: 1 }} /> <Text tid="synkroniser" />
                                                                        </Button>
                                                                    </Box>
                                                                </Card>
                                                            )
                                                        )}



                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                    );
                                })
                            )}
                            {/* Dialogboks for rediger informasjon*/}
                            <Dialog
                                fullWidth
                                maxWidth="lg"
                                open={redigerAktorModal}
                                onClose={handleClickRedigerAktorModalClose}
                            >
                                <DialogTitle><Text tid="redigeropplysningerom" /> {aktorData[0]?.Navn}</DialogTitle>
                                <Box component="form" onSubmit={handleSubmitEndreAktor}>
                                    <DialogContent>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Epost"><Text tid="epost" /></InputLabel>
                                            <Input
                                                id="Epost"
                                                name="Epost"
                                                defaultValue={aktorData[0]?.Epost}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Mobilnummer"><Text tid="mobilnummer" /></InputLabel>
                                            <Input
                                                id="Mobilnummer"
                                                name="Mobilnummer"
                                                defaultValue={aktorData[0]?.Mobilnummer}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Telefonnummer"><Text tid="telefonnummer" /></InputLabel>
                                            <Input
                                                id="Telefonnummer"
                                                name="Telefonnummer"
                                                defaultValue={aktorData[0]?.Telefonnummer}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Webside"><Text tid="webside" /></InputLabel>
                                            <Input
                                                id="Webside"
                                                name="Webside"
                                                defaultValue={aktorData[0]?.Webside}
                                                fullWidth
                                            />
                                        </FormControl>
                                        <Grid container mt={2}>
                                            <Grid mr={1}>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                    <InputLabel htmlFor="Besoksadresse"><Text tid="besoksadresse" /></InputLabel>
                                                    <Input
                                                        id="Besoksadresse"
                                                        name="Besoksadresse"
                                                        defaultValue={aktorData[0]?.Besoksadresse}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid mr={1}>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                    <InputLabel htmlFor="Besokspostnummer"><Text tid="postnummer" /></InputLabel>
                                                    <Input
                                                        id="Besokspostnummer"
                                                        name="Postnummer"
                                                        defaultValue={aktorData[0]?.BesoksPostnummer}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                    <InputLabel htmlFor="Besokspoststed"><Text tid="sted" /></InputLabel>
                                                    <Input
                                                        id="BesoksPoststed"
                                                        name="BesoksPoststed"
                                                        defaultValue={aktorData[0]?.BesoksPoststed}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid mr={1}>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                    <InputLabel htmlFor="Postadresse"><Text tid="postadresse" /></InputLabel>
                                                    <Input
                                                        id="Postadresse"
                                                        name="Postadresse"
                                                        defaultValue={aktorData[0]?.Postadresse}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid mr={1}>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                    <InputLabel htmlFor="Postpostnummer"><Text tid="postnummer" /></InputLabel>
                                                    <Input
                                                        id="PostPostnummer"
                                                        name="PostPostnummer"
                                                        defaultValue={aktorData[0]?.PostPostnummer}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                    <InputLabel htmlFor="Postpoststed"><Text tid="sted" /></InputLabel>
                                                    <Input
                                                        id="PostPoststed"
                                                        name="PostPoststed"
                                                        defaultValue={aktorData[0]?.PostPoststed}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="outlined"
                                            color="success"
                                        >
                                            <SaveIcon className='jvbtnicon' />
                                            <Text tid="lagre" />
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={handleClickRedigerAktorModalClose}
                                        >
                                            <CloseIcon className='jvbtnicon' />
                                            <Text tid="avbryt" />
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>

                            {/* Dialogboks for slett bruker*/}
                            <Dialog
                                open={slettBrukerModal}
                                onClose={handleClickSlettBrukerModalClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Text tid="slettebruker" />
                                </DialogTitle>
                                <Box component="form" onSubmit={handleSubmitSlettBruker}>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Text tid="bekreftslettingavbruker" /> {aktivKontaktpersonNavn} <Text tid="fra" /> {aktorData[0]?.Navn}
                                        </DialogContentText>
                                        <FormControl fullWidth sx={{ mb: 2, mt: 2 }} variant="standard">
                                            <InputLabel htmlFor="sletteaarsak"><Text tid="slettbrukeraarsak" /></InputLabel>
                                            <Input
                                                id="sletteaarsak"
                                                name="sletteaarsak"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                        >
                                            <CloseIcon className='jvbtnicon' />
                                            <Text tid="bekreft" />
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={handleClickSlettBrukerModalClose}
                                        >
                                            <CloseIcon className='jvbtnicon' />
                                            <Text tid="avbryt" />
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>

                            {/* Dialogboks for rediger bruker*/}
                            <Dialog
                                open={redigerBrukerModal}
                                onClose={handleClickRedigerBrukerModalClose}
                            >
                                <DialogTitle><Text tid="redigerbrukertilgang" /></DialogTitle>
                                <Box component="form" onSubmit={handleSubmitEndreBruker} >
                                    <DialogContent>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                            <InputLabel htmlFor="rolle"><Text tid="rolle" /></InputLabel>
                                            <Input
                                                autoFocus
                                                id="rolle"
                                                name="rolle"
                                                defaultValue={aktivKontaktpersonRolle}
                                                fullWidth
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="brukertilgang"><Text tid="brukertilgang" /></InputLabel>
                                            <Select
                                                id="brukertilgang"
                                                name="brukertilgang"
                                                defaultValue={aktivKontaktpersonTilgang}
                                                fullWidth
                                                required
                                            >
                                                <MenuItem value="2"><Text tid="administrator" /></MenuItem>
                                                <MenuItem value="1"><Text tid="verifisertbruker" /></MenuItem>
                                                <MenuItem value="0"><Text tid="ikkeverifisertbruker" /></MenuItem>
                                            </Select>
                                        </FormControl>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            type="submit"

                                        >
                                            <CloseIcon className='jvbtnicon' />
                                            <Text tid="bekreft" />
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={handleClickRedigerBrukerModalClose}
                                        >
                                            <CloseIcon className='jvbtnicon' />
                                            <Text tid="avbryt" />
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>

                            {/* Dialogboks Tilknytt ny servicebedrift */}
                            <Dialog
                                open={servicebedriftTilknyttModal}
                                onClose={handleCloseModalNyServicebedrift}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth="md"
                                fullWidth
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Text tid="tilknyttnyaktor" />
                                </DialogTitle>
                                <Box component="form" onSubmit={handleSokAktor}>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom sx={{ mb: 2 }}>
                                                <Grid container>
                                                    <Grid size={12}>
                                                        <Alert severity="info">
                                                            <Text tid='ainfosok' />
                                                        </Alert>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                            <Grid container>
                                                <Grid size={8}>
                                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                                        <InputLabel htmlFor="navn"><Text tid="sokaktororg" /></InputLabel>
                                                        <Input
                                                            autoFocus
                                                            id="aktorsok"
                                                            name="aktorsok"
                                                            fullWidth
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid size={4} pl={2} pt={2}>
                                                    <Button
                                                        type="submit"
                                                        size="small"
                                                        variant="outlined"
                                                        color="success"
                                                    >
                                                        <SearchIcon className='jvbtnicon' />
                                                        <Text tid="sok" />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {isLoadingAktorSok ?
                                                <div>
                                                    <Backdrop
                                                        open={isLoadingAktorSok}
                                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                                                    >
                                                        <CircularProgress color="inherit" />
                                                    </Backdrop >
                                                </div>
                                                :
                                                null
                                            }
                                            {aktorsok && aktorsok.map(({ navn, orgnr }: any, index: number) => {
                                                return <span key={orgnr}>
                                                    <Card variant="outlined" sx={{ mb: 2 }}>
                                                        <CardContent>
                                                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                                                {navn}
                                                            </Typography>

                                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                                    {orgnr}
                                                                </Typography>
                                                                <Button variant="outlined" color="success" size="small" onClick={e => handleSubmitTilknyttServicebedrift(orgnr)}><AddIcon className='jvbtnicon' /> <Text tid="tilknytt" /></Button>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </span>
                                            })
                                            }
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={handleCloseModalNyServicebedrift}
                                        >
                                            <CloseIcon className='jvbtnicon' />
                                            <Text tid="avbryt" />
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>

                            <Dialog
                                open={slettServicebedriftModal}
                                onClose={handleClickSlettServicebedriftModalClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Text tid="bekreftslettingavservicebedrift" />
                                </DialogTitle>
                                <Box component="form" onSubmit={handleSubmitSlettServicebedrift}>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Text tid="bekreftslettingavservicebedrift" /> {aktivServicebedriftNavn} <Text tid="fra" /> {aktorData[0]?.Navn}
                                        </DialogContentText>
                                        <FormControl fullWidth sx={{ mb: 2, mt: 2 }} variant="standard">
                                            <InputLabel htmlFor="sletteaarsak"><Text tid="aarsak" /></InputLabel>
                                            <Input
                                                id="sletteaarsak"
                                                name="sletteaarsak"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                        >
                                            <Text tid="bekreft" />
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={handleClickSlettServicebedriftModalClose}
                                        >
                                            <Text tid="avbryt" />
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>


                        </Grid>


                        <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                            {aktorData && aktorData[0]?.Servicekode?.length > 0 && (
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                            <PasswordIcon className='jvcardtitleicon' /> <Text tid="servicekode" />
                                        </Typography>
                                    </Box>
                                    <Card variant="outlined" sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Typography variant="body1" color="text.secondary" gutterBottom>
                                                <strong><Text tid="servicekodetittel" /></strong>
                                                <br />
                                                <Text tid="servicekodeinfo" />
                                                <br />
                                                <br />
                                                1. <Text tid="servicekode1" />
                                                <br />
                                                2. <Text tid="servicekode2" />
                                                <br />
                                                <br />
                                                <strong><Text tid="dinservicekode" /></strong>
                                                <br />
                                            </Typography>
                                            <Typography variant="overline"><Typography variant="h5" color="text.secondary">
                                                {aktorData[0]?.Servicekode}
                                            </Typography></Typography>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                        </Grid>


                        {RollerogTillatelser &&
                            <Dialog
                                maxWidth="md"
                                open={RollerogTillatelser}
                                onClose={() => setRollerogTillatelser(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Informasjon om roller og tillatelser
                                </DialogTitle>
                                <DialogContent>
                                    <Typography variant="body1" gutterBottom>
                                        Tilgangsnivåer for brukere
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        🔹Administrator – har full tilgang til alle funksjoner i virksomhetens konto på Dinside. Dette inkluderer: administrasjon av brukere, ekstern tilknytning, og tilgang til SERVICEKODE.
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        🔹Verifisert bruker – Kan sende meldinger, søknader og administrere instrumenter.
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        🔹Ikke-verifisert bruker – Kan "Bli administrator" eller "Verifisert bruker" av en administrator og be om altinn-passord.
                                    </Typography>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={() => setRollerogTillatelser(false)}
                                    >
                                        <Text tid="lukk" />
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                    <AccountCircle className='jvcardtitleicon' /> <Text tid="tilknyttedebrukere" />
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Tilknyttede brukere er personer som har fått tilgang til din virksomhet i Dinside. Disse brukerne kan ha ulike <a href="#" onClick={() => setRollerogTillatelser(true)}>roller og tillatelser</a> avhengig av hva administratoren i virksomheten har gitt dem.
                            </Typography>
                            {Array.isArray(aktorData[0]?.Kontaktpersoner) && aktorData[0]?.Kontaktpersoner.length > 0 && (
                                aktorData[0]?.Kontaktpersoner.map(({ AktorId, KontaktpersonId, Navn, Epost, Mobilnummer, Rolle, Admin, Verifisert }: any, index: number) => {
                                    return (<Card variant="outlined" sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="h6" color="text.secondary" gutterBottom>
                                                    {Navn}
                                                </Typography>
                                                <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
                                                    <IconButton color="primary" onClick={e => handleClickRedigerBrukerModalOpen(KontaktpersonId, Navn, Admin, Verifisert, Rolle)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton color="error" onClick={e => handleClickSlettBrukerModalOpen(KontaktpersonId, Navn)}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                <AlternateEmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                <Typography variant="body2" color="text.secondary">
                                                    {Epost}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                <Typography variant="body2" color="text.secondary">
                                                    {Mobilnummer}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                <Typography variant="body2" color="text.secondary">
                                                    {Rolle}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                <LockPersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                {(Admin) ?
                                                    <Typography variant="body2" className='color-yellow'><Text tid="administrator" /></Typography>
                                                    :
                                                    (Verifisert) ?
                                                        <Typography variant="body2" className='color-green'><Text tid="verifisertbruker" /></Typography>
                                                        :
                                                        <Typography variant="body2" className='color-red'><Text tid="ikkeverifisertbruker" /></Typography>
                                                }
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    );
                                })
                            )}

                        </Grid>


                        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                    <BusinessIcon className='jvcardtitleicon' /> <Text tid="tilknyttedeservicebedrifter" />
                                </Typography>
                                <IconButton color="primary" aria-label="Tilknytt ny servicebedrift" onClick={handleOpenModalNyServicebedrift}>
                                    <AddCircleIcon className='jvtitleactionicon' />
                                </IconButton>
                            </Box>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Nedenfor vises servicefirma(er) du har autorisert til å sende meldinger på vegne av din virksomhet. Disse servicefirma(ene) har også tilgang til å se dine data registrert hos Justervesenet.
                            </Typography>
                            {Array.isArray(aktorData[0]?.Servicebedrifter) && aktorData[0]?.Servicebedrifter.length > 0 && (
                                aktorData[0]?.Servicebedrifter.map(({ Navn, Alias, Epost, Mobilnummer, Telefonnummer, Webside, OrgNummer, Besoksadresse, BesoksPostnummer, BesoksPoststed, Postadresse, PostPostnummer, PostPoststed }: any, index: number) => {
                                    return (
                                        <Card variant="outlined" sx={{ mb: 2 }} key={index}>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                                        {Navn}
                                                    </Typography>
                                                    <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
                                                        <IconButton color="error" onClick={() => handleClickSlettServicebedriftModalOpen(OrgNummer, Navn)}>
                                                            <DeleteForeverIcon />
                                                        </IconButton>

                                                    </Box>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <ListAltIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {OrgNummer}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Telefonnummer}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <SmartphoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Mobilnummer}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <AlternateEmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Epost}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <LanguageIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Webside}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <MailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Postadresse}, {PostPostnummer} {PostPoststed}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 1 }}>
                                                    <HomeIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {Besoksadresse}, {BesoksPostnummer} {BesoksPoststed}
                                                    </Typography>
                                                </Box>

                                            </CardContent>
                                        </Card>
                                    );
                                })
                            )}
                        </Grid>
                    </Grid>


                </div>
            )
            }
        </main >
    );
}

export default Admin;